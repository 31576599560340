import { LeftArrow, RightArrow } from '@/common/ui';
import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { cssVars } from '@/styles/Themes/cssVars';
import Screen from 'src/styles/Themes/Screen';
import Text from 'src/styles/Themes/Text';
import styled from 'styled-components';

export const Wrapper = styled.div<{ noPadding?: boolean }>`
    background-color: ${cssVars.bg_white_blue};
    width: 100%;
    padding: 16px 0;

    ${Screen.ipad} {
        padding: 24px 0;
    }

    > * {
        &:first-child {
            ${({ noPadding }) => noPadding && 'padding-left: 0 !important;'}
        }
    }
`;

export const AlsoViewedWrapper = styled.div`
    background-color: ${cssVars.bg_white_blue};
    padding-top: 20px;
    width: 100%;
`;

export const Block = styled.div`
    max-width: 100%;
    position: relative;
    ${Screen.desktop} {
        padding: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
`;

export const AlsoViewedHeader = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    min-height: 36px;
`;

export const LeftPartHeader = styled.div`
    ${Screen.desktop} {
        width: 90%;
        display: flex;
        flex-direction: column;
    }
`;

export const RightPartHeader = styled.div`
    display: none;

    ${Screen.desktop} {
        width: 50%;
        display: flex;
        justify-content: end;
    }
`;

export const RecommendsBlock = styled.div`
    overflow: hidden;
    margin: -28px -16px 0;

    ${Screen.desktop} {
        margin: -40px -30px 0;
    }
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Full.heading2}
`;

export const SubTitle = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.medium};

    ${Screen.ipad} {
        top: 35px;
    }

    ${Screen.desktop} {
        padding: 6px 0 0;
    }
`;

export const Price = styled.div`
    ${Text.Mobile.medium.semibold}
    background-color: ${cssVars.bg_white_blue};
    color: ${cssVars.fg_gray1_white};
    min-width: 52px;
    min-height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Card = styled.a`
    display: flex;
    background: ${cssVars.bg_white_dark3};
    box-shadow: 0px 3px 23px ${cssVars.shadow_normal};
    border-radius: ${radius.small};
    overflow: hidden;
    min-width: 310px;
    min-height: 152px;
    margin-right: 4%;
    padding: 20px;
    cursor: pointer;

    ${Screen.ipad} {
        max-height: 200px;
        padding: 30px 10px 30px 30px;
    }

    ${Screen.desktop} {
        min-width: 24%;
        margin-right: 1.4%;
        max-height: 205px;
    }
`;

export const CategoryTitle = styled.span`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.medium.semibold}
    display: block;
    margin-top: 14px;
`;

export const CarouselWraper = styled.div`
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: calc(100% + 32px);
    padding: 0 16px;
    transform: translateX(-16px);

    ${Screen.ipad} {
        width: calc(100% + 48px);
        padding: 0 24px;
        transform: translateX(-24px);
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to left, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            0deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to right, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            180deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
`;

export const Gallery = styled.div`
    display: flex;
    user-select: none;

    overflow-x: auto;
    scroll-behavior: smooth;
    transition: 0.5s ease 0s;
    box-sizing: border-box;

    ::-webkit-scrollbar {
        display: none;
    }

    width: calc(100% + 32px);
    padding: 16px;
    transform: translateX(-16px);

    ${Screen.ipad} {
        width: calc(100% + 48px);
        padding: 24px;
        transform: translateX(-24px);
    }
`;

export const AlsoViewedGallery = styled.div`
    display: flex;
    user-select: none;

    overflow-x: auto;
    scroll-behavior: smooth;
    transition: 0.5s ease 0s;
    box-sizing: border-box;

    ::-webkit-scrollbar {
        display: none;
    }

    width: calc(100% + 32px);
    transform: translateX(-16px);

    ${Screen.ipad} {
        width: calc(100% + 48px);
        /* transform: translateX(-24px); */
    }
`;

export const InnerContainer = styled.div`
    gap: 24px;
    transition: 0.5s ease 0s;
    width: auto;
    display: flex;
`;

export const AlsoViewedInnerContainer = styled.div`
    gap: 24px;
    transition: 0.5s ease 0s;
    width: auto;
    display: flex;
`;

export const CenterLeftArrow = styled(LeftArrow)`
    z-index: 2;
    position: absolute;
    left: 5px;
    top: 45%;
`;

export const CenterRightArrow = styled(RightArrow)`
    z-index: 2;
    position: absolute;
    right: 5px;
    top: 45%;
`;

export const DotsSlider = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    height: 30px;

    ${Screen.desktop} {
        display: none;
    }
`;

export const TabsWrapper = styled.div<{ reverse?: boolean }>`
    display: flex;
    width: fit-content;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
`;

export const Tab = styled.div<{ isActive?: boolean }>`
    min-width: 220px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 8px;
    color: ${({ isActive }) => (isActive ? cssVars.fg_gray1_white : cssVars.fg_gray2_gray3)};

    &::after {
        content: '';
        height: 5px;
        width: 100%;
        margin-top: 8px;
        border-radius: 5px;
        background-color: ${({ isActive }) => (isActive ? Palette.green.normal : 'transparent')};
    }

    & span {
        margin: auto;
        padding-left: 10px;
        padding-right: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    ${Text.Full.large.semibold}

    ${Screen.ipadMini} {
        ${Text.Mobile.large.semibold}
        min-width: unset;
    }
`;

export const TabDivider = styled.div`
    width: 1px;
    height: 25px;
    margin: 0 11px;
    background-color: ${cssVars.bg_gray4_dark1};

    ${Screen.ipadMini} {
        height: 32px;
    }
`;
